<template>
  <div class="archive">
    <div class="archive__classes" v-if="$store.getters.PROFILE.myClasses.length > 1">
      <p
          @click="changeSchoolClassArchive(cl)"
          class="archive__classes__text"
          :class="{'archive__classes__text_selected' : $store.getters.SCHOOL_CLASS_ARCHIVE.id === cl.id}"
          v-for="cl of this.$store.getters.PROFILE.myClasses"
          v-bind:key="cl.id">
        {{cl.fullName}} {{cl.type == 1 ? 'группа' : 'класс'}}</p>
    </div>
    <ArchiveListView
        v-bind:key="$store.getters.SCHOOL_CLASS_ARCHIVE.id"
        class="archive__list"/>
  </div>
</template>

<script>
import ArchiveListView from "./ArchiveListView";
export default {
  name: "ArchiveDesktopListView",
  components: {ArchiveListView},
  methods:{
    async changeSchoolClassArchive(cl){
      await this.$store.commit("SET_SCHOOL_CLASS_ARCHIVE",cl)
      await this.$store.dispatch("parseArchiveBySchoolClass",{
        educationObjectId: cl.educationObjectId,
        schoolClassId: cl.id
      })
    }
  },
  async mounted() {
    await this.$store.dispatch("UPDATE_LOCAL_PROFILE")
    const cl = this.$store.getters.PROFILE.myClasses[0]
    await this.$store.dispatch("parseArchiveBySchoolClass",{
      educationObjectId: cl.educationObjectId,
      schoolClassId: cl.id
    })
    await this.$store.commit("SET_SCHOOL_CLASS_ARCHIVE",cl)
  }
}
</script>

<style scoped lang="scss">
  .archive{
    width: 100%;
    height: 100%;
    overflow: scroll;
    &__classes{
      height: 50px;
      background: #FFFFFF;
      border: 1px solid #EBEDEE;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      &__text{
        width: 80px;
        height: 26px;
        background: #788396;
        border-radius: 13px;
        text-align: center;
        font-family: CodeNext;
        font-weight: bold;
        font-size: 13px;
        color: #FFFFFF;
        letter-spacing: 0;
        line-height: 26px;
        margin-right: 30px;
        cursor: pointer;

        &_selected{
          background: #333942;
        }
      }

      &__text:last-child{
        margin-right: 0;
      }
    }

    &__list{
      margin-top: 40px;
    }
  }
</style>
